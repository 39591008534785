<template>
  <div
    id="charterWindowDestination"
    ref="charterWindowDestination"
    key="charterWindowDestination"
    class="charter-search__window"
  >
    <div class="charter-search__window-content overflow-auto">
      <PackageSearchBarWindowHeader
        class="-mx-3 sm:-mx-3 px-4 md:px-0 md:mx-0"
        show-next
        :show-close="false"
        :select-error="selectDestinationFirst ? $t('charterSelectDestinationFirst') : null"
        @next="$emit('search')"
      >
        {{ $t('charterSelectDestinations') }}
      </PackageSearchBarWindowHeader>
      <ScreenWidthProvider
        v-slot="{ isNarrower }"
        class="charter-search__list checkboxes mt-2"
      >
        <div
          v-if="categoriesForAirport.length"
          class="flex gap-3 flex-wrap lg:mb-3 pb-4 md:px-0 -mx-1 sm:mx-0"
        >
          <div
            v-for="(categoryChunk, idx) in chunkedCategoriesForAirport"
            :key="idx"
            class="flex lg:contents justify-between w-full gap-3"
          >
            <ClickableChip
              v-for="category in categoryChunk"
              :key="category.id"
              class="mb-auto text-left charter-destination-select__category-chip"
              :active="stagedCategoriesIds.includes(category.id)"
              :icon="stagedCategoriesIds.includes(category.id) ? icons.faCheck : undefined"
              inactive-class="bg-light-turquoise"
              active-class="!bg-vivid-turquoise"
              @click="toggleCategory(category)"
            >
              {{ category.name }}
            </ClickableChip>
          </div>
          <div class="inline-flex flex-col ml-auto">
            <button
              class="shadow-none btn-sm btn-light rounded-2xl px-4 font-medium"
              data-test="clear-destination-options"
              @click="handleCategories([])"
            >
              <fa
                class="hidden lg:inline mr-2"
                icon="xmark"
              />{{ $t('charterUnselectDestinations') }}
            </button>
            <button
              v-if="isNarrower('lg')"
              class="shadow-none btn-sm btn-light rounded-2xl capitalize mt-2 px-4 font-medium"
              @click="$emit('clear')"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
        <div
          v-if="!whitelabel"
          class="flex"
        >
          <RelativeDropdown
            class="px-4 -mx-3 lg:-mx-2 lg:px-2 grow flex flex-col"
            :initial-expanded="isNarrower('lg') || !categoriesForAirport.length"
            menu-class="-mx-2 lg:px-2"
            width="unset"
            dropdown-class="flex"
          >
            <template #trigger="{ toggle, isOpen }">
              <button
                class="shadow-none text-black normal-case -mx-3 lg:mx-0 text-xl py-2 px-4 lg:px-4 text-left flex items-center justify-between grow bg-light-turquoise font-medium"
                type="button"
                :class="{ 'pointer-events-none': isNarrower('lg') || !categoriesForAirport.length }"
                data-test-destination-dropdowns-toggle
                @click="toggle"
              >
                <div
                  v-if="isNarrower('lg') || !categoriesForAirport.length"
                  class="pl-1"
                >
                  {{ $t('packagessearchbar.seeAllTripsMobile') }}
                </div>
                <template v-else>
                  {{ $t('packagessearchbar.seeAllTrips') }}
                  <fa
                    v-if="categoriesForAirport.length"
                    :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                  />
                </template>
              </button>
            </template>
            <template #content>
              <ul class="columns-3xs w-full charter-search__list px-2 md:px-0 pt-2 lg:mt-1 lg:pt-3">
                <li
                  v-for="(destinationL1, index) in preparedDestinations"
                  :key="`d${index}`"
                  class="px-2 -mx-2 mb-2 charter-destination-select__country-group overflow-hidden"
                  :class="{ 'active-country': stagedDestinationsL1ForAirport.includes(destinationL1.name) }"
                  data-test-country-group
                >
                  <button
                    class="font-bold !bg-transparent"
                    data-test-country-button
                    @click="handleDestinationL1(destinationL1.name)"
                  >
                    {{ destinationL1.name }}
                  </button>
                  <ul class="mb-0">
                    <li
                      v-for="destination in destinationL1.destinations"
                      :key="destination.destination_id"
                      class="px-2"
                      :class="{ 'active-destination': stagedDestinationsIds.includes(destination.destination_id) && !stagedDestinationsL1ForAirport.includes(destinationL1.name) }"
                    >
                      <button
                        class="!bg-transparent"
                        :class="{ 'hover:bold': !stagedDestinationsIds.includes(destination.destination_id) }"
                        data-test-destination-button
                        @click="handleDestination(destination)"
                      >
                        {{ destination.name }}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
          </RelativeDropdown>
        </div>
      </ScreenWidthProvider>
    </div>
  </div>
</template>

<script>
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { mapState, mapActions } from 'pinia'

export default defineNuxtComponent({
  name: 'DestinationSelect',

  props: {
    selectDestinationFirst: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['search', 'clear'],

  data () {
    return {
      icons: {
        faCheck,
        faTimes,
      },
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, [
      'categories',
      'destinations',
      'groupedDestinationsForAirport',
      'categoriesForAirport',
    ]),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinations: state => state.destinations.stagedDestinations,
      stagedCategories: state => state.destinations.stagedCategories,
      stagedDestinationsL1ForAirport: 'stagedDestinationsL1ForAirport',
      stagedCategoriesIds: 'stagedCategoriesIds',
    }),

    ...mapState(useWhitelabelStore, {
      whitelabel: 'whitelabel',
    }),

    chunkedCategoriesForAirport () {
      return chunkArray(this.categoriesForAirport, 2)
    },

    stagedDestinationsIds () {
      return this.stagedDestinations.map(d => d?.destination_id).filter(d => !!d)
    },

    preparedDestinations () {
      return this.groupedDestinationsForAirport
        .map(x => ({
          ...x,
          destinations: x.destinations?.filter(({ name }) => name !== x.name),
        }))
    },
  },

  async fetch () {
    // NOTE this should be fetched at page level, added this here just in case it's not
    await this.fetchCharterDestinations()
  },

  methods: {
    ...mapActions(useSearchWidgetsStore, [
      'handleDestination',
      'handleDestinationL1',
      'toggleCategory',
      'handleCategories',
    ]),

    ...mapActions(useRootStore, ['fetchCharterDestinations']),
  },
})
</script>

<style lang="scss" scoped>
$active-color: theme('colors.vivid-turquoise');
$active-border-radius: 10px;
$active-animation-duration: 150ms;

.charter-destination-select__category-chip {
  @media (max-width: theme('screens.sm')) {
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    font-size: 0.74rem;
  }
}

.charter-destination-select {
  &__country-group {
    border-radius: $active-border-radius;
    transition: background $active-animation-duration ease-in-out;

    li {
      transition-property: background, border-radius;
      transition-duration: $active-animation-duration;
      transition-timing-function: ease-in-out;
    }

    &.active-country {
      background: $active-color !important;
    }

    .active-destination {
      background: $active-color !important;
      border-top-left-radius: $active-border-radius;
      border-top-right-radius: $active-border-radius;

      &:last-of-type,
      &:has(+ :not(.active-destination)) {
        border-bottom-left-radius: $active-border-radius;
        border-bottom-right-radius: $active-border-radius;
      }

      & + .active-destination {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
}
</style>
